<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="info"
        v-bind="attrs"
        v-on="on"
        style="min-width: 20px !important; padding: 10px !important"
      >
        <v-icon>
          {{ icons.mdiAccountConvert }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="cont-icon">
          <v-icon class="icon" size="50">
            {{ icons.mdiAccountConvert }}
          </v-icon>
        </div>
        <span class="q-title">Would you like to impersonate to {{ email }}?</span>
      </v-card-title>
      <v-card-text
        ><p>
          Impersonation allows users with the admin or the impersonator role to temporarily become another authenticated
          user for testing purposes. Impersonation does not require knowing another user's password.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="impersonateAccount"> Continue </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { mdiAccountConvert } from '@mdi/js'

export default {
  props: {
    email: String,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      icons: {
        mdiAccountConvert,
      },
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    ...mapActions({
      impersonate: 'auth/impersonate',
      setSnackbar: 'snackbar/set',
    }),
    async impersonateAccount() {
      const form_data = {
        email: this.email,
      }
      await this.impersonate(form_data)
        .then(resp => {
          if (resp && resp.data) {
            if (!(this.$router.history.current.name == 'dashboard')) {
              this.$router.push('/')
            }
          }
        })
        .finally(() => {
          this.dialog = false
        })
    },
  },
}
</script>

<style lang="css" scoped>
.q-title {
  word-break: break-word;
  text-align: center;
}
.cont-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.icon {
  font-size: 60px;
}
</style>
